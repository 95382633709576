import { Button, Container, FormInput, Grid, useToast } from '@aurecon-creative-technologies/styleguide'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from 'recoil'
import { appInsights } from 'src/api/AppInsights'
import { FetchReportMappingDataRequestModel, fetchReportsDetails, generateReport } from 'src/api/ReportService'
import LoadingScreen from 'src/components/LoadingScreen'
import ProcessAverageContingencyFCASPriceChart from 'src/components/charts/ProcessAverageContingencyFCASPriceChart'
import ProcessAverageRegulationFCASPriceChart from 'src/components/charts/ProcessAverageRegulationFCASPriceChart'
import ProcessBatteryMarketDispatchStateChargeProfile from 'src/components/charts/ProcessBatteryMarketDispatchStateChargeProfile'
import ProcessFCASRevenueChart from 'src/components/charts/ProcessFCASRevenueChart'
import ProcessPreferredScenarioChart from 'src/components/charts/ProcessPreferredScenarioChart'
import ProcessSampleDayFCASPriceData from 'src/components/charts/ProcessSampleDayFCASPriceData'
import ProcessSampleDispatchProfilesData from 'src/components/charts/ProcessSampleDispatchProfilesData'
import ProcessSampleStateChargeProfilesData from 'src/components/charts/ProcessSampleStateChargeProfilesData'
import ProcessWholesaleArbitragePriceSpreadChart from 'src/components/charts/ProcessWholesaleArbitragePriceSpreadChart'
import ProcessWholesaleEnergyPriceChart from 'src/components/charts/ProcessWholesaleEnergyPriceChart'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { dateToString } from 'src/helpers/utils'
import {
  GenerateReportRequest,
  RegFCASRegisteredCapacity,
  ReportSection,
  TechnicalAssumption,
} from 'src/models/api/GenerateReportRequestFormData'
import { GenerateReportRequestDefaults } from 'src/models/api/GenerateReportRequestFormDataDefault'
import {
  MappingData,
  PreferredScenario,
  Scenario,
  WholesaleArbitragePriceSpread,
} from 'src/models/api/ReportMappingResponseModel'
import { fetchReportsDataSelector, inProductedSecretState, selectedProjectIdState } from 'src/stores/AppStore'
import * as Yup from 'yup'
import Style from '../../styles/Reports.module.sass'
import { GenerateReportRequestSchema } from './GenerateReportValidationSchema'
import CommercialAndMarketAssemptions from './Table/CommercialAndMarketAssemptions'
import CommercialPerformanceTable from './Table/CommercialPerformance'
import ProjectInputs from './Table/ProjectInputs'
import ScenarioSummaryTable from './Table/ScenarioSummary'
import TechnicalAssumptions from './Table/TechnicalAssumptions'
import TextEditor from './TextEditor/TextEditor'
import ProcessAverageDailyEnergyDispatchChart from 'src/components/charts/ProcessAverageDailyEnergyDispatchChart'
import { ReportTechnicalAssumption } from 'src/models/api/ReportDetailResponseModal'

export interface SelectScenarioProps {
  handleToggleView: (data?: FetchReportMappingDataRequestModel) => void
}

export interface ScenarioOverviewProps {
  handleToggleView: (data?: FetchReportMappingDataRequestModel) => void
  data?: FetchReportMappingDataRequestModel | null
}

const prepareTechnicalAssumptions = (
  bess_inputs: ReportTechnicalAssumption[],
  wholesaleArbitragePriceSpread: WholesaleArbitragePriceSpread[],
  firstYearOfCommercialOperation: number,
): TechnicalAssumption[] => {
  const technicalAssumptionList: TechnicalAssumption[] = []
  bess_inputs?.map((item, i) => {
    technicalAssumptionList.push({
      year: firstYearOfCommercialOperation + i,
      operating_year: item.operating_year,
      energy_storage_retention: item.energy_discharge_capacity?.toString() || '0',
      round_trip_efficiency: item.rte?.toString() || '0',
      re_generation: wholesaleArbitragePriceSpread
        ? wholesaleArbitragePriceSpread[i]?.['re_generation']?.toString()
        : '0',
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      created_by: '',
      updated_by: '',
    })
  })
  return technicalAssumptionList
}

const prepareRegulationFcas = (
  rated_discharge_power: number,
  rated_charge_power: number,
): RegFCASRegisteredCapacity => {
  const reg_fcas_registered_capacity: RegFCASRegisteredCapacity = {
    raise_reg: rated_discharge_power,
    lower_reg: rated_charge_power,
  }
  return reg_fcas_registered_capacity
}

const ScenarioOverview = ({ handleToggleView, data }: ScenarioOverviewProps) => {
  if (appInsights) {
    appInsights.trackPageView({ name: 'Report Generation' })
  }
  const navigate = useNavigate()
  const secretState = useRecoilValue(inProductedSecretState)
  const [preferredScenarioData, setPreferredScenarioData] = useState<PreferredScenario>()
  const [topScenarioData, setTopScenarioData] = useState<Scenario[]>()
  const [mappingData, setMappingData] = useState<MappingData>()
  const projectId = useRecoilValue(selectedProjectIdState)
  const componentRef = useRef<HTMLDivElement>(null)
  const [formData, setFormData] = useState<GenerateReportRequest>(GenerateReportRequestDefaults)
  const [projectLife, setProjectLife] = useState<number>()
  const [firstYearOfCommercialOperation, setFirstYearOfCommercialOperation] = useState<number>()
  const [projectType, setProjectType] = useState<number | undefined>()
  const showToast = useToast()
  const [errors, setErrors] = useState<Record<string, string>>({})
  const refreshFetchItems = useRecoilRefresher_UNSTABLE(fetchReportsDataSelector)
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('')
  const [isInitialRender, setIsInitialRender] = useState(true)

  useEffect(() => {
    const fetchDetails = async () => {
      if (!data || !projectId) return
      try {
        setLoading(true)
        setLoadingText('Fetching data')
        const cloneReportId = data.clone_report_id ?? 0
        const response = await fetchReportsDetails(
          data.execution_scenario_association_id as number,
          projectId,
          cloneReportId as number,
          data.preferred_date,
        )
        if (response?.success === false) {
          showToast.addToast({ type: 'error', message: 'Report details not found!', timeout: 3000 })
          handleToggleView()
          return
        }
        const preferredScenarioData = response?.['data']?.['mapping_data']?.['preferred_scenario']
        const topScenarioData = response?.['data']?.['mapping_data']?.['top_scenarios']
        const clonedData = response?.['data']?.['clone_data']
        if (preferredScenarioData) {
          setPreferredScenarioData(preferredScenarioData)
          setMappingData(response?.['data']?.['mapping_data'])
          setTopScenarioData(topScenarioData)
          setProjectLife(
            preferredScenarioData.preferred_scenario?.input_parameters?.scenario_detail?.project_life_years,
          )
          setFirstYearOfCommercialOperation(
            preferredScenarioData.preferred_scenario?.input_parameters?.scenario_detail
              .first_year_of_commercial_operation,
          )
          setProjectType(
            preferredScenarioData?.preferred_scenario?.input_parameters?.scenario_detail?.scenario_project_type,
          )
          const preferredYear = Number(dateToString(data.preferred_date, 'yyyy'))
          const totalRevenueItem = preferredScenarioData?.preferred_scenario?.npv_details?.total_net_revenue.find(
            (item: { year: number; value: number }) => item.year === preferredYear,
          )
          preferredScenarioData.npv_chart.total = totalRevenueItem.value
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const getCapContract = (response: any, index: number): string => {
            return response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
              'input_parameters'
            ]?.['scenario_detail']?.[index]?.['is_cap_contract_intended'] === true
              ? 'Yes'
              : 'No'
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const updateMappingItem = (item: any, index: number, response: any) => {
            return {
              ...item,
              cap_contract: getCapContract(response, index),
            }
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const updateReportScenarioMapping = (response: any, prevReportScenarioMapping: any[]): any[] => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const updatedMapping: any[] = []

            for (let index = 0; index < prevReportScenarioMapping.length; index++) {
              const item = prevReportScenarioMapping[index]
              const updatedItem = updateMappingItem(item, index, response)
              updatedMapping.push(updatedItem)
            }

            return updatedMapping
          }

          // Usage inside setFormData
          setFormData((prevFormData) => {
            const updatedFormData = {
              ...prevFormData,
              report_detail: {
                ...prevFormData.report_detail,
                report_scenario_mapping: updateReportScenarioMapping(
                  response,
                  prevFormData.report_detail.report_scenario_mapping,
                ),
                commercial_operation_date:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['commercial_operation_date'],
                economic_life:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['economic_life'],
                average_total_o_and_m_cost:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['average_total_o_and_m'],
                capital_cost:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['capital_cost'],
                commercial_and_market_assumptions:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ] || prevFormData.report_detail.commercial_and_market_assumptions,
                pop_wholesale_energy_generation:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['pop_wholesale_energy_generation'] ||
                  preferredScenarioData.preferred_scenario?.input_parameters?.scenario_detail
                    .pop_wholesale_energy_generation,
                pop_reg_fcas:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['pop_reg_fcas'] ||
                  preferredScenarioData.preferred_scenario?.input_parameters?.scenario_detail.pop_reg_fcas,
                pop_contingency_fcas:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['pop_contingency_fcas'] ||
                  preferredScenarioData.preferred_scenario?.input_parameters?.scenario_detail.pop_contingency_fcas,
                generation_marginal_loss_factor_start_of_life:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['generation_marginal_loss_factor_start_of_life'] ||
                  prevFormData.report_detail.generation_marginal_loss_factor_start_of_life,
                generation_marginal_loss_factor_end_of_life:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['generation_marginal_loss_factor_end_of_life'] ||
                  prevFormData.report_detail.generation_marginal_loss_factor_end_of_life,
                load_marginal_loss_factor_start_of_life:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['load_marginal_loss_factor_start_of_life'] ||
                  prevFormData.report_detail.load_marginal_loss_factor_start_of_life,
                load_marginal_loss_factor_end_of_life:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['load_marginal_loss_factor_end_of_life'] ||
                  prevFormData.report_detail.load_marginal_loss_factor_end_of_life,
                technical_assumptions: prepareTechnicalAssumptions(
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.input_parameters
                    ?.scenario_detail?.bess_inputs,
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.[
                    'wholesale_arbitrage_price_spread_chart'
                  ],
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['commercial_operation_date'],
                ),
                average_permissable_cycle:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['average_permissible_cycles'],
                standby_power_consumption:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['standby_power_consumption'],
                standby_power_consumption_unit:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['standby_power_consumption_unit'],
                contingency_fcas_registered_capacity:
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'commercial_and_market_assumptions'
                  ]?.['contingency_fcas_registered_capacity'],
                regulation_fcas_registered_capacity: prepareRegulationFcas(
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['rated_discharge_power'],
                  response?.['data']?.['mapping_data']?.['preferred_scenario']?.['preferred_scenario']?.[
                    'input_parameters'
                  ]?.['scenario_detail']?.['rated_charge_power'],
                ),
              },
            }

            return updatedFormData
          })

          if (clonedData?.report_detail?.report_section) {
            setFormData((prevFormData) => ({
              ...prevFormData,
              report_detail: {
                ...prevFormData.report_detail,
                report_section: updateReportSectionFormattedComment(clonedData.report_detail.report_section),
              },
            }))
          }
        }
      } catch (err) {
        console.error('Error fetching or processing report details:', err)
      } finally {
        setLoading(false)
      }
    }
    if (isInitialRender) {
      fetchDetails()
      setIsInitialRender(false)
    }
  }, [data, projectId, isInitialRender, showToast, handleToggleView])
  const updateReportSectionFormattedComment = (reportSection: ReportSection[]): ReportSection[] => {
    return reportSection.map((section: ReportSection) => ({
      ...section,
      formatted_comment: section.formatted_comment || '',
    }))
  }

  const updateReportSectionGeneralComment = (
    sections: ReportSection[],
    sectionId: number,
    comment: string,
  ): ReportSection[] => {
    return sections.map((section) =>
      section.section_id === sectionId ? { ...section, general_comment: comment } : section,
    )
  }

  const handleChange = useCallback(
    (value: string, name: string) => {
      setFormData((prevFormData) => {
        let updatedReportDetail = prevFormData.report_detail

        if (name === 'preferredScenarioIntroComment') {
          updatedReportDetail = {
            ...updatedReportDetail,
            report_section: updateReportSectionGeneralComment(updatedReportDetail.report_section, 2, value),
          }
        } else if (name === 'initialIntroComment') {
          updatedReportDetail = {
            ...updatedReportDetail,
            report_section: updateReportSectionGeneralComment(updatedReportDetail.report_section, 1, value),
          }
        } else {
          return { ...prevFormData, [name]: value }
        }

        return {
          ...prevFormData,
          report_detail: updatedReportDetail,
        }
      })
    },
    [setFormData],
  )

  const reportValidationsCheck = async () => {
    try {
      await GenerateReportRequestSchema.validate(formData, { abortEarly: false })
      handleGenerateReportSubmit()
    } catch (validationErrors) {
      const err = validationErrors as Yup.ValidationError
      const errorMessages: { [key: string]: string } = {}
      err.inner.forEach((error) => {
        if (error.path) {
          errorMessages[error.path] = error.message
        }
      })
      setErrors(errorMessages)
    }
  }

  const handleGenerateReportSubmit = async () => {
    try {
      setErrors({})
      setLoading(true)
      setLoadingText('Generating report')
      formData.project_id = projectId
      formData.preferred_date = data?.preferred_date.toString() ?? new Date().toISOString()
      formData.report_detail.commercial_operation_date =
        formData.report_detail?.commercial_operation_date.toString().split('T')[0] ?? new Date().toISOString()
      formData.name = data?.name ?? ''
      formData.execution_scenario_association_id = mappingData?.top_scenarios[0]?.execution_scenario_association_id
        ? Number(mappingData?.top_scenarios[0]?.execution_scenario_association_id)
        : 0
      formData.preferred_scenario_id = Number(mappingData?.top_scenarios[0].scenario_id)
      const cleanedFormData = removeUnwantedKeys(formData)
      const response = await generateReport(cleanedFormData)
      if (response?.['id']) {
        showToast.addToast({ type: 'success', message: 'Report generated successfully!', timeout: 3000 })
        refreshFetchItems()
        navigate(`/dashboard/${projectId}/reportdetail/${response?.['id']}/${projectType}`)
      } else {
        showToast.addToast({ type: 'error', message: 'Failed to generate report!', timeout: 3000 })
      }
    } catch (error) {
      console.error('Error generating report:', error)
      showToast.addToast({ type: 'error', message: 'Failed to generate report!', timeout: 3000 })
    } finally {
      setLoading(false)
    }
  }

  const removeUnwantedKeys = (data: GenerateReportRequest): GenerateReportRequest => {
    const keysToRemove = [
      'bess_inputs',
      'cap_contracts',
      'capex',
      'commercial_parameters',
      'marginal_loss_factors',
      'market_inputs',
      'network_support_services',
      'repex',
    ]
    const newData = { ...data }
    keysToRemove.forEach((key) => {
      if (newData[key as keyof GenerateReportRequest]) {
        delete newData[key as keyof GenerateReportRequest]
      }
    })
    return newData
  }
  return (
    <Container fluid cssClass={`${Style.paddingX} ${Style.pageBtm}`}>
      {loading ? (
        <LoadingScreen text={loadingText} />
      ) : (
        <section className={Style.overview_scenario} ref={componentRef}>
          <section className='page-content print-page' id='1'>
            <h3 className={Style.reportTitle}>{data?.name}</h3>
            <h3 className={Style.reportSubtitle}>Scenario overview</h3>
            <h5>
              <strong>Preferred Scenario </strong>
              {preferredScenarioData?.['preferred_scenario']?.['input_parameters']?.['name']}
            </h5>
            <FormInput
              type='multiline'
              label={'Introductory comment'}
              placeholder={'Enter your comments'}
              value={formData?.report_detail.report_section[0]?.general_comment || ''}
              onChange={(v) => handleChange(v, 'initialIntroComment')}
            />
            {topScenarioData && (
              <>
                <ScenarioSummaryTable scenario={topScenarioData} formData={formData} setFormData={setFormData} />
                <CommercialPerformanceTable scenario={topScenarioData} />
              </>
            )}
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='2'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className={Style.chart_container}>
                  <h4>Preferred scenario results</h4>
                  <div className={Style.chartIrrContainer}>
                    <div className='grid grid-item'>
                      {mappingData && (
                        <ProcessPreferredScenarioChart
                          data={mappingData?.preferred_scenario.npv_chart}
                          setFormData={setFormData}
                          sectionId={'2'}
                        />
                      )}
                    </div>
                    <div className={Style.scenarioIrr}>
                      <h2>Scenario IRR</h2>
                      <h1>{preferredScenarioData?.['preferred_scenario']?.['irr_percentage'].toFixed(2)} %</h1>
                      <p>{preferredScenarioData?.['preferred_scenario']?.['input_parameters']?.['name']}</p>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <div className='grid grid-item'>
                  <h4>Comments </h4>
                  <TextEditor setFormData={setFormData} formData={formData} sectionId='2' />
                </div>
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='3'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Commercial and market assumptions</h4>
                <div className={Style.table_container}>
                  <CommercialAndMarketAssemptions formData={formData} error={errors} setFormData={setFormData} />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='3' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='4'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Technical assumptions</h4>
                <div className={Style.table_container}>
                  {projectLife && firstYearOfCommercialOperation && (
                    <TechnicalAssumptions projectType={projectType} formData={formData} />
                  )}
                  <ProjectInputs
                    average_permissable_cycle={formData.report_detail.average_permissable_cycle}
                    standby_power_consumption={formData.report_detail.standby_power_consumption}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='4' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='5'>
            <h4>Network support service details </h4>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='5' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='6'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Average Weekly Wholesale Energy Price</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <ProcessWholesaleEnergyPriceChart
                      data={mappingData?.preferred_scenario.wholesale_arbitrage_price_shape_chart}
                      setFormData={setFormData}
                      sectionId={'6'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='6' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='7'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Wholesale Arbitrage Price Spread</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <ProcessWholesaleArbitragePriceSpreadChart
                      data={mappingData?.preferred_scenario.wholesale_arbitrage_price_spread_chart}
                      setFormData={setFormData}
                      sectionId={'7'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='7' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='8'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Average Daily Energy Dispatch</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <ProcessAverageDailyEnergyDispatchChart
                      data={mappingData?.preferred_scenario.average_daily_energy_dispatch_chart}
                      setFormData={setFormData}
                      sectionId={'8'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='8' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='9'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Average Weekly Wholesale Energy Price</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <>
                      <ProcessAverageRegulationFCASPriceChart
                        data={mappingData?.preferred_scenario.fcas_chart_details}
                        setFormData={setFormData}
                        sectionId={'9'}
                      />
                      <ProcessAverageContingencyFCASPriceChart
                        data={mappingData?.preferred_scenario.fcas_chart_details}
                        setFormData={setFormData}
                        sectionId={'9'}
                      />

                      <ProcessSampleDayFCASPriceData
                        data={mappingData?.preferred_scenario?.fcas_chart_details.fcas_for_preferred_day}
                        preferred_date={data?.preferred_date ? new Date(data?.preferred_date).toLocaleDateString() : ''}
                        setFormData={setFormData}
                        sectionId={'9'}
                      />
                    </>
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='9' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='10'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>FCAS Revenues</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <ProcessFCASRevenueChart
                      rawData={mappingData?.preferred_scenario.fcas_chart_details}
                      setFormData={setFormData}
                      sectionId={'10'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='10' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='11'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Sample State of Charge Profile</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <ProcessSampleStateChargeProfilesData
                      data={mappingData?.preferred_scenario.sample_dispatch_profiles_chart}
                      setFormData={setFormData}
                      sectionId={'11'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='11' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='12'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Sample Market Dispatch Profile</h4>
                <div className={Style.chart_container}>
                  {mappingData && (
                    <ProcessSampleDispatchProfilesData
                      data={mappingData?.preferred_scenario?.sample_dispatch_profiles_chart}
                      setFormData={setFormData}
                      sectionId={'12'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='12' />
              </Grid>
            </Grid>
          </section>
          <section className={`${Style.chart_section} print-page chart-container`} id='13'>
            <Grid row gap={12}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Battery operation and state of charge profile</h4>
                <div className={Style.chart_container}>
                  {data?.preferred_date && (
                    <div className='text-center'>{new Date(data.preferred_date).toLocaleDateString()}</div>
                  )}
                  {mappingData && (
                    <ProcessBatteryMarketDispatchStateChargeProfile
                      data={mappingData?.preferred_scenario?.fcas_chart_details.battery_market_dispatch_chart}
                      setFormData={setFormData}
                      sectionId={'13'}
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                <h4>Comments </h4>
                <TextEditor setFormData={setFormData} formData={formData} sectionId='13' />
              </Grid>
            </Grid>
          </section>
          <div className={Style.btn_container}>
            <Button
              type='custom'
              cssClass={Style.btnPrevious}
              label='Previous'
              size='small'
              onClick={() => handleToggleView()}
            />
            {secretState && !isRoleEmpty(secretState.roles) && (
              <Button
                type='custom'
                cssClass={Style.btnGenerate}
                label='Generate'
                size='small'
                onClick={reportValidationsCheck}
              />
            )}
          </div>
        </section>
      )}
    </Container>
  )
}

export default ScenarioOverview
