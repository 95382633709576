import { Container, Dropdown, Grid, SearchBox, TabContent, TabMenu } from '@aurecon-creative-technologies/styleguide'
import { FC, useCallback, useEffect, useState } from 'react'
import { appInsights } from 'src/api/AppInsights'
import Style from '../../styles/Dashboard.module.sass'
import EnergyBreakdown from './EnergyBreakdown'
import NPVBreakdown from './NPVbreakdown'
import OverallSummary from './OverallSummary'
import { useNavigate } from 'react-router-dom'
import {
  dashboardKpiDataSelector,
  dashboardCurrentPage,
  dashboardNvbBreakdown,
  dashboardOverallSummary,
  dashboardSearchQueryState,
  dashboardSortColumnState,
  dashboardSortOrderState,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { ReactComponent as AsecIcon } from '../../assets/asecicon.svg'
import { ReactComponent as DescIcon } from '../../assets/descicon.svg'
import { debounce } from 'lodash'
import { visitedHeaderTabsState } from 'src/stores/LayoutStore'
import { NavbarLabelConst } from 'src/constants/LayoutConst'

const OVERALL_SUMMARY_DROPDOWN_ITEMS = [
  { id: 'name', label: 'Scenario Name' },
  { id: 'created_at', label: 'Added Date' },
  { id: 'updated_at', label: 'Modified Date' },
]

const GRID_ITEMS = [
  { id: 1, title: 'Forecast Data', value: 0, key: 'forecast', path: '/forecasting', buttonText: 'View Forecast Data' },
  { id: 2, title: 'Scenarios', value: 0, key: 'scenario', path: '/scenario', buttonText: 'View Scenarios List' },
  { id: 3, title: 'Execution', value: 0, key: 'execution', path: '/executionlist', buttonText: 'View All Execution' },
  { id: 4, title: 'Reports', value: 0, key: 'report', path: '/reportlist', buttonText: 'View All Reports' },
]

const SortButton: FC<{ sortOrder: string | null; onClick: () => void }> = ({ sortOrder, onClick }) => {
  return (
    <button onClick={onClick} className={Style.sortBtn} aria-label='Sort order'>
      {sortOrder === 'desc' && <AsecIcon height='20' />}
      {sortOrder === 'asc' && <DescIcon height='20' />}
      {sortOrder === null && <span style={{ width: '20px', height: '20px' }}></span>}{' '}
    </button>
  )
}

const ProjectDashboard: FC = () => {
  if (appInsights) appInsights.trackPageView({ name: 'Dashboard' })
  const navigate = useNavigate()
  const setCurrentPage = useSetRecoilState(dashboardCurrentPage)
  const [selectedProjectId] = useRecoilState(selectedProjectIdState)
  const [SortOrderState, setSortOrderState] = useRecoilState(dashboardSortOrderState)
  const [, setSearchQueryState] = useRecoilState(dashboardSearchQueryState)
  const [sortColumnState, setSortColumnState] = useRecoilState(dashboardSortColumnState)
  const dashboardCounts = useRecoilValue(dashboardKpiDataSelector)
  const [activeTab, setActiveTab] = useState<number>(1)

  const refreshOverAll = useRecoilRefresher_UNSTABLE(dashboardOverallSummary)
  const refreshFetchNpv = useRecoilRefresher_UNSTABLE(dashboardNvbBreakdown)
  const refreshDashboardKpi = useRecoilRefresher_UNSTABLE(dashboardKpiDataSelector)

  const [visitedHeaderTabs, setVisitedHeaderTabs] = useRecoilState(visitedHeaderTabsState)

  const isVisitedDashboardTab = visitedHeaderTabs.find((f) => f === NavbarLabelConst.Dashboard)

  useEffect(() => {
    if (!isVisitedDashboardTab) return

    refreshDashboardKpi()
  }, [refreshDashboardKpi, isVisitedDashboardTab])

  useEffect(() => {
    return () => {
      if (isVisitedDashboardTab) return
      setVisitedHeaderTabs((state) => [...state, NavbarLabelConst.Dashboard])
    }
  }, [setVisitedHeaderTabs, isVisitedDashboardTab])

  const handleSortButtonClick = () => {
    const newSortOrder = SortOrderState === 'asc' ? 'desc' : 'asc'
    setSortOrderState(newSortOrder)
  }

  const handleActiveTab = (tab: number) => {
    setCurrentPage(1)
    setSortColumnState('created_at')
    setSortOrderState('asc')
    setSearchQueryState('')
    setActiveTab(tab)
  }
  const handleClearSearch = () => {
    setSearchQueryState('')
  }

  const handleSort = useCallback(
    (sortColumnValue: string) => {
      setSortColumnState(sortColumnValue)
      if (activeTab == 1) {
        refreshOverAll()
      } else if (activeTab == 2) {
        refreshFetchNpv()
      }
    },
    [activeTab, refreshFetchNpv, refreshOverAll, setSortColumnState],
  )

  const handleSearch = debounce((query: string) => {
    setSearchQueryState(query)
  }, 300)

  return (
    <Container fluid>
      <header className={Style.header}>
        <Container fluid cssClass={Style.paddingX}>
          <div className={Style.header_wrapper}>
            <h1 className={Style.titleHead}>Dashboard</h1>
          </div>
        </Container>
      </header>
      <div className={Style.marginBtm}>
        <Container fluid cssClass={Style.paddingX}>
          <section className={Style.card}>
            <Grid row gap={6}>
              {GRID_ITEMS.map((item) => (
                <Grid key={item.id} item xs={12} sm={6} md={6} lg={6} xl={3}>
                  <div className={`${Style.card_wrapper} ${Style.full_width}`}>
                    <h5>{item.title}</h5>
                    <div className={Style.card_footer}>
                      <h3>{dashboardCounts['data']?.[item.key] ?? item.value}</h3>
                      <button
                        className={Style.btnRedirect}
                        onClick={() => navigate(`/dashboard/${selectedProjectId}${item.path}`)}
                      >
                        {item.buttonText}
                      </button>
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </section>
          {activeTab !== 3 && (
            <section className={Style.filter}>
              <div className={(Style.search, Style.searchContainer)}>
                {activeTab === 2 && (
                  <SearchBox
                    cssClass='search_box'
                    placeholder={'Search for a scenario/project...'}
                    onChange={handleSearch}
                    onClear={handleClearSearch}
                  />
                )}
              </div>
              <div>
                <div className={Style.sortText}>
                  <p className={Style.sort}>Sort</p>
                  <div>
                    <Dropdown
                      placeholder={'Created Date'}
                      items={OVERALL_SUMMARY_DROPDOWN_ITEMS}
                      selectedItem={sortColumnState}
                      onSelectItem={(v) => handleSort(v.toString())}
                      optionsHeight={300}
                      cssClass='is-custom'
                    />
                  </div>
                  <SortButton sortOrder={SortOrderState} onClick={handleSortButtonClick} />
                </div>
              </div>
            </section>
          )}
          <section className='tab_container'>
            <TabMenu
              tabs={[
                { id: 1, label: 'Overall summary' },
                { id: 2, label: 'NPV Breakdown' },
                { id: 3, label: 'Energy Breakdown' },
              ]}
              activeTab={activeTab}
              onSelectTab={(id) => handleActiveTab(id)}
            >
              <TabContent for={1}>
                <OverallSummary />
              </TabContent>
              <TabContent for={2}>
                <NPVBreakdown />
              </TabContent>
              <TabContent for={3}>
                <EnergyBreakdown />
              </TabContent>
            </TabMenu>
          </section>
        </Container>
      </div>
    </Container>
  )
}

export default ProjectDashboard
