import { Button, FormInput, Grid, Table, TableCell, TableRow } from '@aurecon-creative-technologies/styleguide'
import { useCallback } from 'react'
import { CapexValue, ProjectInput, ProjectInputLgc, RepexValue } from 'src/enums/ScenarioEnums'
import { getCurrentDateISOString } from 'src/helpers/utils'
import { RepxValuesInterface, ScenarioFormData } from 'src/models/ScenarioFormData'
import Style from '../../styles/CreateScenario.module.sass'

const projectInputHeaders = [{ label: ProjectInput.COMMERCIAL_PARAMETERS }, { label: ProjectInput.VALUE }]
const capexValueHeaders = [{ label: CapexValue.YEAR }, { label: CapexValue.VALUE }]
const capexValueHeadersHybrid = [
  { label: CapexValue.YEAR },
  { label: CapexValue.BESS },
  { label: CapexValue.REGENERATOR },
  { label: CapexValue.COMMONEQUIPMENT },
]
const repexValueHeaders = [{ label: RepexValue.YEAR }, { label: RepexValue.VALUE }]

interface Step6Props {
  formData: ScenarioFormData
  setFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors: Record<string, string>
}

export const Step6: React.FC<Step6Props> = ({ formData, setFormData, errors }) => {
  const handleProjectInputData = (value: string | number | boolean, name: string) => {
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handleCommercialData = (id: number, field: string, value: string) => {
    const updatedCollecteData = formData.commercial_parameters.map((cap) => {
      if (cap.operating_year === id) {
        return {
          ...cap,
          [field]: value,
        }
      }
      return cap
    })

    setFormData({
      ...formData,
      commercial_parameters: updatedCollecteData,
    })
  }

  const handleRepexData = (id: number, field: string, value: string) => {
    const updatedCollecteData = formData.repex.map((cap) => {
      if (cap.operating_year === id) {
        return {
          ...cap,
          [field]: value,
        }
      }
      return cap
    })

    setFormData({
      ...formData,
      repex: updatedCollecteData,
    })
  }

  const handleCapexCollectDataChange = (id: number, field: string, value: string) => {
    const updatedCollecteData = formData.capex.map((cap) => {
      if (cap.operating_year === id) {
        return {
          ...cap,
          [field]: value,
        }
      }
      return cap
    })

    setFormData({
      ...formData,
      capex: updatedCollecteData,
    })
  }
  const addRepexInput = useCallback(() => {
    const numberOfObjectsToAdd = Number(formData.project_life_years)
    const repexData: RepxValuesInterface[] = [...formData.repex]
    const currentLossFactorsLength = repexData.length
    if (numberOfObjectsToAdd > currentLossFactorsLength) {
      const newObj = {
        created_at: getCurrentDateISOString(),
        updated_at: getCurrentDateISOString(),
        created_by: getCurrentDateISOString(),
        updated_by: getCurrentDateISOString(),
        operating_year: currentLossFactorsLength > 0 ? repexData.slice(-1)[0].operating_year + 1 : 1,
        year:
          currentLossFactorsLength > 0
            ? Number(repexData.slice(-1)[0].year) + 1
            : Number(formData.first_year_of_commercial_operation),
        mid_life_repex: 0,
        comments: '',
      }
      repexData.push(newObj)
      setFormData((prevData) => ({
        ...prevData,
        repex: repexData,
      }))
    }
  }, [formData.first_year_of_commercial_operation, formData.project_life_years, formData.repex, setFormData])

  return (
    <div className='mainContainerBlock'>
      <Grid row gap={12}>
        <Grid item lg={12}>
          <h2>Project Input</h2>
          <Table headers={projectInputHeaders}>
            <TableRow>
              <TableCell>O&M + Repex Inflation Rate (annual, real basis) (%)</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.o_and_m_inflation_rate}
                  onChange={(v) => {
                    handleProjectInputData(v, 'o_and_m_inflation_rate')
                  }}
                  required={true}
                  error={errors.o_and_m_inflation_rate}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Percent of Perfect - Wholesale Energy Generation</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.pop_wholesale_energy_generation}
                  onChange={(v) => {
                    handleProjectInputData(v, 'pop_wholesale_energy_generation')
                  }}
                  required={true}
                  error={errors.pop_wholesale_energy_generation}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Percent of Perfect - Wholesale Energy Load</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.pop_wholesale_energy_load}
                  onChange={(v) => {
                    handleProjectInputData(v, 'pop_wholesale_energy_load')
                  }}
                  required={true}
                  error={errors.pop_wholesale_energy_load}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Percent of Perfect - Reg FCAS</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.pop_reg_fcas}
                  onChange={(v) => {
                    handleProjectInputData(v, 'pop_reg_fcas')
                  }}
                  required={true}
                  error={errors.pop_reg_fcas}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Percent of Perfect - Contingency FCAS</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.pop_contingency_fcas}
                  onChange={(v) => {
                    handleProjectInputData(v, 'pop_contingency_fcas')
                  }}
                  required={true}
                  error={errors.pop_contingency_fcas}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Standard Inflation Rate</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.standard_inflation_rate}
                  onChange={(v) => {
                    handleProjectInputData(v, 'standard_inflation_rate')
                  }}
                  required={true}
                  error={errors.standard_inflation_rate}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Discount rate</TableCell>
              <TableCell>
                <FormInput
                  type='text'
                  value={formData.npv_discount_rate}
                  onChange={(v) => {
                    handleProjectInputData(v, 'npv_discount_rate')
                  }}
                  required={true}
                  error={errors.npv_discount_rate}
                />
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
        <h3 className='featHead'>Capex</h3>
        <Grid item lg={12} cssClass={Style.p10}>
          <Table headers={String(formData.scenario_project_type) === '1' ? capexValueHeaders : capexValueHeadersHybrid}>
            {formData.capex.map((data) =>
              String(formData.scenario_project_type) === '1' ? (
                <TableRow key={data.operating_year}>
                  <TableCell cellClass={Style.tableTBPadding}>
                    <FormInput
                      type='text'
                      label={''}
                      placeholder={''}
                      default={!data.year}
                      error={errors[`capex[${data.operating_year}].year`]}
                      value={data.year.toString()}
                      onChange={(v) => handleCapexCollectDataChange(data.operating_year, 'year', v)}
                      readonly
                      required={false}
                    />
                  </TableCell>

                  <TableCell>
                    <FormInput
                      type='text'
                      value={data.capex_estimate.toString()}
                      onChange={(v) => handleCapexCollectDataChange(data.operating_year, 'capex_estimate', v)}
                      required={false}
                      label={''}
                      placeholder={''}
                      default={!data.capex_estimate}
                      error={errors[`capex[${data.operating_year}].capex_estimate`]}
                    />
                  </TableCell>
                </TableRow>
              ) : (
                String(formData.scenario_project_type) === '2' && (
                  <TableRow key={data.operating_year}>
                    <TableCell cellClass={Style.tableTBPadding}>
                      <FormInput
                        type='text'
                        label={''}
                        placeholder={''}
                        value={data.year.toString()}
                        onChange={(v) => handleCapexCollectDataChange(data.operating_year, 'year', v)}
                        required={false}
                        readonly
                        default={!data.year}
                        error={errors[`capex[${data.operating_year}].year`]}
                      />
                    </TableCell>
                    <TableCell>
                      <FormInput
                        type='text'
                        label={''}
                        placeholder={''}
                        value={data.bess_estimate.toString()}
                        onChange={(v) => handleCapexCollectDataChange(data.operating_year, 'bess_estimate', v)}
                        required={false}
                        default={!data.bess_estimate}
                        error={errors[`capex[${data.operating_year}].bess_estimate`]}
                      />
                    </TableCell>
                    <TableCell>
                      <FormInput
                        type='text'
                        label={''}
                        placeholder={''}
                        value={data.re_generator_estimate.toString()}
                        onChange={(v) => handleCapexCollectDataChange(data.operating_year, 're_generator_estimate', v)}
                        required={false}
                        default={!data.re_generator_estimate}
                        error={errors[`capex[${data.operating_year}].re_generator_estimate`]}
                      />
                    </TableCell>
                    <TableCell>
                      <FormInput
                        type='text'
                        label={''}
                        placeholder={''}
                        value={data.common_equipment.toString()}
                        onChange={(v) => handleCapexCollectDataChange(data.operating_year, 'common_equipment', v)}
                        required={false}
                        default={!data.common_equipment}
                        error={errors[`capex[${data.operating_year}].common_equipment`]}
                      />
                    </TableCell>
                  </TableRow>
                )
              ),
            )}
          </Table>
        </Grid>
        <h3 className='featHead'>Repex</h3>
        <Grid item lg={12} cssClass={Style.p10}>
          <Table headers={repexValueHeaders}>
            {formData.repex.map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.year.toString()}
                    onChange={(v) => handleRepexData(data.operating_year, 'year', v)}
                    required={false}
                    default={!data.year}
                    error={errors[`repex[${data.operating_year - 1}].year`]}
                  />
                </TableCell>
                <TableCell>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.mid_life_repex.toString()}
                    onChange={(v) => handleRepexData(data.operating_year, 'mid_life_repex', v)}
                    required={false}
                    default={!data.mid_life_repex}
                    error={errors[`repex[${data.operating_year - 1}].mid_life_repex`]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
          {errors.repex && (
            <Grid item lg={12} cssClass={Style.p10}>
              <span className={Style.error}> {errors.repex}</span>
            </Grid>
          )}

          {formData.repex.length <=
            Number(formData.first_year_of_commercial_operation) + Number(formData.project_life_years) && (
            <Grid item lg={12}>
              <Button cssClass={Style.mt10} type='icon' rounded size='small' icon='add' onClick={addRepexInput} />
            </Grid>
          )}
        </Grid>
        <h3 className='featHead'>Others</h3>
        <Grid item lg={12} cssClass={Style.p10}>
          <Table
            headers={[
              { label: ProjectInputLgc.YEAR },
              { label: ProjectInputLgc.REGLTSA },
              { label: formData.is_manual_ltsa_input ? ProjectInputLgc.LTSA_MANUAL : ProjectInputLgc.LTSA_CALCULATED },
              {
                label: formData.is_manual_warranty_input
                  ? ProjectInputLgc.WARRANTY_MANUAL
                  : ProjectInputLgc.WARRANTY_CALCULATED,
              },
              { label: ProjectInputLgc.TUOS },
              { label: ProjectInputLgc.OTHER },
            ]}
          >
            {formData.commercial_parameters.slice(0, parseInt(formData.project_life_years)).map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.re_generator_long_term_service_agreement_cost.toString()}
                    onChange={(v) =>
                      handleCommercialData(data.operating_year, 're_generator_long_term_service_agreement_cost', v)
                    }
                    required={false}
                    default={!data.re_generator_long_term_service_agreement_cost}
                    error={
                      errors[
                        `commercial_parameters[${
                          data.operating_year - 1
                        }].re_generator_long_term_service_agreement_cost`
                      ]
                    }
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={
                      formData.is_manual_ltsa_input
                        ? data.manual_long_term_service_agreement_cost
                        : data.long_term_service_agreement_cost_percentage
                    }
                    onChange={(v) => {
                      if (!formData.is_manual_ltsa_input) {
                        handleCommercialData(data.operating_year, 'manual_long_term_service_agreement_cost', '0')
                        handleCommercialData(data.operating_year, 'long_term_service_agreement_cost_percentage', v)
                      }
                      if (formData.is_manual_ltsa_input) {
                        handleCommercialData(data.operating_year, 'long_term_service_agreement_cost_percentage', '0')
                        handleCommercialData(data.operating_year, 'manual_long_term_service_agreement_cost', v)
                      }
                    }}
                    onBlur={() =>
                      !formData.is_manual_ltsa_input
                        ? handleCommercialData(data.operating_year, 'manual_long_term_service_agreement_cost', '0')
                        : handleCommercialData(data.operating_year, 'long_term_service_agreement_cost_percentage', '0')
                    }
                    required={false}
                    default={
                      formData.is_manual_ltsa_input
                        ? !data.manual_long_term_service_agreement_cost
                        : !data.long_term_service_agreement_cost_percentage
                    }
                    error={
                      formData.is_manual_ltsa_input
                        ? errors[
                            `commercial_parameters[${data.operating_year - 1}].manual_long_term_service_agreement_cost`
                          ]
                        : errors[
                            `commercial_parameters[${
                              data.operating_year - 1
                            }].long_term_service_agreement_cost_percentage`
                          ]
                    }
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={
                      formData.is_manual_warranty_input
                        ? data.manual_warranty_cost_absolute
                        : data.warranty_cost_percentage
                    }
                    onChange={(v) => {
                      if (!formData.is_manual_warranty_input) {
                        handleCommercialData(data.operating_year, 'manual_warranty_cost_absolute', '0')
                        handleCommercialData(data.operating_year, 'warranty_cost_percentage', v)
                      }
                      if (formData.is_manual_warranty_input) {
                        handleCommercialData(data.operating_year, 'warranty_cost_percentage', '0')
                        handleCommercialData(data.operating_year, 'manual_warranty_cost_absolute', v)
                      }
                    }}
                    onBlur={() =>
                      !formData.is_manual_warranty_input
                        ? handleCommercialData(data.operating_year, 'manual_warranty_cost_absolute', '0')
                        : handleCommercialData(data.operating_year, 'warranty_cost_percentage', '0')
                    }
                    required={false}
                    default={
                      formData.is_manual_warranty_input
                        ? !data.manual_warranty_cost_absolute
                        : !data.warranty_cost_percentage
                    }
                    error={
                      formData.is_manual_warranty_input
                        ? errors[`commercial_parameters[${data.operating_year - 1}].manual_warranty_cost_absolute`]
                        : errors[`commercial_parameters[${data.operating_year - 1}].warranty_cost_percentage`]
                    }
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.tuos_cost.toString()}
                    onChange={(v) => handleCommercialData(data.operating_year, 'tuos_cost', v)}
                    required={false}
                    default={!data.tuos_cost}
                    error={errors[`commercial_parameters[${data.operating_year - 1}].tuos_cost`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.other_o_and_m_cost}
                    onChange={(v) => handleCommercialData(data.operating_year, 'other_o_and_m_cost', v)}
                    required={false}
                    default={!data.other_o_and_m_cost}
                    error={errors[`commercial_parameters[${data.operating_year - 1}].other_o_and_m_cost`]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
        <h3 className='featHead'>LGC</h3>
        <Grid item lg={12} cssClass={Style.p10}>
          <Table
            headers={[
              { label: ProjectInputLgc.YEAR },
              { label: ProjectInputLgc.LGC },
              { label: ProjectInputLgc.LGC_FRACTION },
            ]}
          >
            {formData.commercial_parameters.slice(0, parseInt(formData.project_life_years)).map((data) => (
              <TableRow key={data.operating_year}>
                <TableCell cellClass={Style.tableDataPadding}>{data.year}</TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.lgc_price.toString()}
                    onChange={(v) => handleCommercialData(data.operating_year, 'lgc_price', v)}
                    required={false}
                    default={!data.lgc_price}
                    error={errors[`commercial_parameters[${data.operating_year}].lgc_price`]}
                  />
                </TableCell>
                <TableCell cellClass={Style.tableDataPadding}>
                  <FormInput
                    type='text'
                    label={''}
                    placeholder={''}
                    value={data.lgc_fraction}
                    onChange={(v) => handleCommercialData(data.operating_year, 'lgc_fraction', v)}
                    required={false}
                    default={!data.lgc_fraction}
                    error={errors[`commercial_parameters[${data.operating_year}].lgc_fraction`]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </Grid>
      </Grid>
    </div>
  )
}
