import { Button, Grid, Modal } from '@aurecon-creative-technologies/styleguide'
import { useNavigate } from 'react-router-dom'
import noImageBanner from 'src/assets/noImageBanner.jpg'
import { dateToString } from 'src/helpers/utils'
import { Project } from 'src/models/api/Projects'
import DeleteProject from 'src/pages/DeleteProject'
import Style from '../styles/ProjectCard.module.sass'
import { useRecoilValue } from 'recoil'
import { isRoleEmpty } from 'src/helpers/appRoles'
import { inProductedSecretState } from 'src/stores/AppStore'
import { useModal } from './Modal'

const ProjectCard: React.FC<{ data: Project }> = ({ data }) => {
  const navigate = useNavigate()
  const SecretState = useRecoilValue(inProductedSecretState)
  const { isShowing, toggleModal } = useModal(false)
  const handleEditProjectClick = (projectId: number) => {
    navigate(`/editproject/${projectId}`)
  }

  const handleDeleteProjectClick = () => {
    toggleModal()
  }

  const handleCloseModal = () => {
    toggleModal()
  }

  const handleOpenDashboardClick = (projectId: number) => {
    navigate('/dashboard/' + projectId)
  }

  return (
    <Grid item xs={12} sm={6} md={12} lg={6} xl={6} cssClass={Style.tileGrid}>
      <div className={Style.storybook_grid_cell}>
        <div className={Style.card}>
          <img
            className={Style.bannerimage}
            src={data.image?.image_data ? `data:image/jpeg;base64,${data.image?.image_data}` : noImageBanner}
            alt={data.name}
          />
          <div className={Style.cardheader}>
            <h4>
              {data.is_confidential && <Button type='custom' size='medium' icon='work' cssClass={Style.workIcon} />}
              {data.name}
            </h4>
            <div className={Style.rowcol}>
              <p className={Style.projectSubhead}>Created Date</p>
              <p className={Style.projectSubhead}>{dateToString(data.created_at)}</p>
            </div>
            <div className={Style.rowcolClient}>
              <p className={Style.projectSubhead}>Client</p>
              <p className={Style.projectSubhead}>{data.client?.client_name}</p>
            </div>
          </div>
          <div className={Style.cardbody}>
            <p className={Style.projectDescription}>{data.description}</p>
          </div>
          <div className={Style.cardfooter}>
            <Button
              onClick={() => handleOpenDashboardClick(data.id)}
              type='custom'
              icon='open_in_new'
              size='medium'
              label='Open Dashboard'
              cssClass={Style.btnDashboard}
            />
            <div className={Style.actionicons}>
              <Button
                type='icon'
                rounded
                size='medium'
                icon='settings'
                onClick={() => handleEditProjectClick(data.id)}
                cssClass={Style.btnIcon}
              />
              {SecretState && !isRoleEmpty(SecretState.roles) && (
                <Button
                  type='icon'
                  rounded
                  size='medium'
                  icon='delete'
                  onClick={handleDeleteProjectClick}
                  cssClass={Style.btnIcon}
                />
              )}

              <Modal
                isShowing={isShowing}
                onClose={toggleModal}
                size='medium'
                cssClass='modalBackgroundWhite'
                isCloseButton={true}
                isOverlay={true}
                shouldOverlayClose={true}
              >
                <div>
                  <DeleteProject projectId={data.id.toString()} onClose={handleCloseModal} />
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  )
}

export default ProjectCard
