import {
  Accordion,
  AccordionPanel,
  Dropdown,
  FormInput,
  Grid,
  InfoTooltip,
  Radio,
} from '@aurecon-creative-technologies/styleguide'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { coupledType, hybridGenerationType } from 'src/enums/ScenarioEnums'
import { ForecastInfoScenario, GenerationInfoScenario } from 'src/models/api/ForecastModalResponse'
import { ScenarioFormData } from 'src/models/ScenarioFormData'
import {
  formCurrentForecastStartDate,
  getAllForecastWithFileTypeDetails,
  getAllForecastWithGenerationFileTypeDetails,
  scenarioProjectIdState,
  selectedProjectIdState,
} from 'src/stores/AppStore'
import Style from '../../styles/CreateScenario.module.sass'
import { getCapacityLabel } from 'src/helpers/scenarioHelpers'

interface Step1Props {
  formData: ScenarioFormData
  formTypeData: boolean
  setFormData: React.Dispatch<React.SetStateAction<ScenarioFormData>>
  errors: Record<string, string>
}
interface ErrorState {
  project_life_years?: string
  first_year_of_commercial_operation?: string
}

interface DropdownItem {
  id: string
  label: string
  start_date: string
  period: number
  interval: number
}

const SamplingIntervalHeader = [
  { id: 5, label: '5 minutes' },
  { id: 10, label: '10 minutes' },
  { id: 15, label: '15 minutes' },
  { id: 30, label: '30 minutes' },
  { id: 60, label: '60 minutes' },
]

export const Step1: React.FC<Step1Props> = ({ formData, formTypeData, setFormData, errors }) => {
  const [selected, setSelected] = useState<string[]>(['p1', 'p2'])
  const projectId = useRecoilValue(selectedProjectIdState)
  const [forecastList, setForecastList] = useState<DropdownItem[]>([])
  const [generationList, setGenerationList] = useState<DropdownItem[]>([])
  const setSelectedProjectId = useSetRecoilState(scenarioProjectIdState)
  const fetchGenerationDataDetails = useRecoilValue(getAllForecastWithGenerationFileTypeDetails)
  const fetchForecastDataDetails = useRecoilValue(getAllForecastWithFileTypeDetails)
  const [customErrors, setCustomErrors] = useState<ErrorState>({})
  const [forecastStartDate, setForecastStartDate] = useState<number>()
  const currentForecastStartDate = useRecoilValue(formCurrentForecastStartDate)

  const togglePanel = (panel: string) => {
    const index = selected.indexOf(panel)
    if (index === -1) {
      setSelected((sel) => [...sel, panel])
    } else {
      const newSel = [...selected]
      newSel.splice(index, 1)
      setSelected(newSel)
    }
  }

  useEffect(() => {
    setSelectedProjectId(projectId)

    let maxTimeInterval = 0
    let maxPeriod = 0

    if (fetchGenerationDataDetails && typeof fetchGenerationDataDetails === 'object') {
      const generationDataArray = Object.values(fetchGenerationDataDetails).filter(
        (item) => typeof item === 'object',
      ) as Array<GenerationInfoScenario>

      const generationFormatted = generationDataArray
        .filter((item) => item.file_processing_status.name === 'SUCCESSFUL')
        .map((item) => {
          if (item.interval > maxTimeInterval) {
            maxTimeInterval = item.interval
          }
          if (item.period > maxPeriod) {
            maxPeriod = item.period
          }
          return {
            id: item.id.toString(),
            label: item.file_name,
            start_date: item.start_date,
            interval: item.interval,
            period: item.period,
            profile_type: item.profile_type,
          }
        })

      setGenerationList(generationFormatted)
    }

    if (fetchForecastDataDetails && typeof fetchForecastDataDetails === 'object') {
      const forecastDataArray = Object.values(fetchForecastDataDetails).filter(
        (item) => typeof item === 'object',
      ) as Array<ForecastInfoScenario>

      const forecastFormatted = forecastDataArray
        .filter((item) => item.file_processing_status.name === 'SUCCESSFUL')
        .map((item) => {
          if (item.interval > maxTimeInterval) {
            maxTimeInterval = item.interval
          }
          if (item.period > maxPeriod) {
            maxPeriod = item.period
          }
          return {
            id: item.id.toString(),
            label: item.file_name,
            start_date: item.start_date,
            interval: item.interval,
            period: item.period,
          }
        })

      setForecastList(forecastFormatted)
      if (currentForecastStartDate) {
        setForecastStartDate(parseInt(currentForecastStartDate.split('-')[0], 10))
      }
      const defaultSamplingIntervalId = SamplingIntervalHeader.find((interval) => interval.id === maxTimeInterval)?.id

      if (
        !formData.time_res &&
        (Number(formData.scenario_project_type) === 1 || defaultSamplingIntervalId !== undefined)
      ) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          time_res: 30,
        }))
      }

      if (Number(formData.project_life_years) > maxPeriod) {
        setCustomErrors((prev) => ({
          ...prev,
          project_life_years: `Project life years cannot exceed forecast file and generation profile period`,
        }))
      } else {
        setCustomErrors((prev) => ({ ...prev, project_life_years: '' }))
      }
    }
  }, [
    fetchForecastDataDetails,
    fetchGenerationDataDetails,
    formData.scenario_project_type,
    formData.project_life_years,
    projectId,
    setSelectedProjectId,
    formData.time_res,
    setFormData,
    currentForecastStartDate,
  ])

  const handleChange = (value: string | number, name: string) => {
    switch (name) {
      case 'hybrid_generation_type':
        if (value === '1') {
          setFormData({
            ...formData,
            hybrid_generation_type: value,
            coupled_type: '1',
            max_bess_inverter_capacity_out: '',
            max_bess_inverter_capacity_in: '',
            max_vre_inverter_capacity_out: '',
          })
          return
        } else if (value === '2') {
          setFormData({
            ...formData,
            hybrid_generation_type: value.toString(),
            coupled_type: '2',
            max_vre_inverter_capacity_out: '',
          })
          return
        }
        break

      case 'coupled_type':
        if (value) {
          setFormData({
            ...formData,
            coupled_type: value.toString(),
            max_bess_inverter_capacity_out: '',
            max_bess_inverter_capacity_in: '',
            max_vre_inverter_capacity_out: '',
          })
          return
        }
        break
      default:
        setFormData({
          ...formData,
          [name]: value,
        })
        break
    }
  }
  const handleForecastChange = (value: string | number, interval: string) => {
    const selectedForecastFile = forecastList.find((item) => item.id == value)
    if (!selectedForecastFile) return

    setForecastStartDate(parseInt(selectedForecastFile.start_date.split('-')[0], 10))
    setFormData((prev) => ({ ...prev, [interval]: value }))
  }
  const handleGenerationChange = (value: string | number, interval: string) => {
    setFormData((prev) => ({ ...prev, [interval]: value }))
  }

  const handleBlurValidation = (value: string) => {
    if (forecastStartDate) {
      const startYear = parseInt(forecastStartDate.toString(), 10)
      if (parseInt(value) !== startYear) {
        setCustomErrors((prevErrors) => ({
          ...prevErrors,
          first_year_of_commercial_operation: `Year of Commercial Operation must be same as the Start year in associated Forecast file`,
        }))
        setFormData((prevFormData) => ({
          ...prevFormData,
          first_year_of_commercial_operation: '',
        }))
      } else {
        setCustomErrors((prevErrors) => ({
          ...prevErrors,
          first_year_of_commercial_operation: '',
        }))
      }
    }
  }

  return (
    <div className={Style.marginBtmStepOne}>
      <h2>Project setup</h2>
      <Accordion activePanelIds={selected} onPanelToggle={togglePanel}>
        <AccordionPanel
          cssContentClass={Style.accordionpanel}
          panelId='p1'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>General parameters</h3>
            </div>
          }
        >
          <Grid row gap={12}>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Scenario Name'}
                placeholder={'Name here'}
                readonly={formTypeData}
                value={formData.name}
                onChange={(v) => handleChange(v, 'name')}
                required={false}
              />
            </Grid>
            <Grid item lg={4}>
              <div className={classNames(Style.formGroup, 'input_forms')}>
                <Dropdown
                  label='Associated Forecast File'
                  items={forecastList.length > 0 ? forecastList : []}
                  optionsHeight={250}
                  selectedItem={formData.forecast_data_id}
                  onSelectItem={(v) => {
                    handleForecastChange(v, 'forecast_data_id')
                  }}
                  required={false}
                />
                {errors.forecast_data_id && <p className={Style.errorMess}>{errors.forecast_data_id}</p>}
              </div>
            </Grid>
            {String(formData.scenario_project_type) === '2' && (
              <Grid item lg={4}>
                <div className={classNames(Style.formGroup, 'input_forms')}>
                  <Dropdown
                    label='Generation Profile'
                    items={generationList.length > 0 ? generationList : []}
                    optionsHeight={250}
                    selectedItem={formData.generating_profile_id}
                    onSelectItem={(v) => {
                      handleGenerationChange(v, 'generating_profile_id')
                    }}
                    required={false}
                  />
                  {errors.generating_profile_id && <p className={Style.errorMess}>{errors.generating_profile_id}</p>}
                </div>
              </Grid>
            )}

            <Grid item lg={12}>
              <FormInput
                type='multiline'
                label={'Description'}
                placeholder={'Please provide descriptions for this forecast...'}
                value={formData.description}
                onChange={(v) => handleChange(v, 'description')}
                required={false}
              />
            </Grid>
            <Grid item lg={4}>
              <FormInput
                type='text'
                label={'Project life (years)'}
                placeholder={''}
                value={formData.project_life_years.toString()}
                onChange={(v) => {
                  handleChange(v, 'project_life_years')
                }}
                required={true}
                error={errors.project_life_years}
              />
              {customErrors.project_life_years && <p className={Style.errorMess}>{customErrors.project_life_years}</p>}
            </Grid>

            <Grid item lg={4}>
              <span className={Style.labelHead}>
                <b>
                  First year of commercial operation <span className='asterix'>*</span>
                  <InfoTooltip show='Year from which the BESS system is operational and start to generate revenue' />
                </b>
              </span>
              <FormInput
                type='text'
                label={''}
                placeholder={''}
                value={formData.first_year_of_commercial_operation.toString()}
                onChange={(v) => {
                  handleChange(v, 'first_year_of_commercial_operation')
                }}
                onBlur={() => handleBlurValidation(formData.first_year_of_commercial_operation.toString())}
                required={true}
                error={errors.first_year_of_commercial_operation}
              />
              {customErrors.first_year_of_commercial_operation && (
                <p className={Style.errorMess}>{customErrors.first_year_of_commercial_operation}</p>
              )}
            </Grid>
            <Grid item lg={4}>
              <span className={Style.labelHead}>
                <b>
                  Year of Deployment <span className='asterix'>*</span>
                  <InfoTooltip show='Year of the commencement of installation of BESS system. This is required to track the Capital expenditure associated with BESS set-up.' />
                </b>
              </span>
              <FormInput
                type='text'
                label={''}
                placeholder={''}
                value={formData.year_of_deployment}
                onChange={(v) => {
                  handleChange(v, 'year_of_deployment')
                }}
                required={true}
                error={errors.year_of_deployment}
              />
            </Grid>

            <Grid item lg={12} gap={12}>
              <Grid item lg={4}>
                <FormInput
                  type='text'
                  label={'Max Energy Output to Grid'}
                  placeholder={''}
                  value={formData.max_energy_output_to_grid}
                  onChange={(v) => {
                    handleChange(v, 'max_energy_output_to_grid')
                  }}
                  required={true}
                  error={errors.max_energy_output_to_grid}
                />
              </Grid>
              <Grid item lg={2}>
                <p className={Style.unitSubTextStep1}>MW</p>
              </Grid>
              <Grid item lg={4}>
                <FormInput
                  type='text'
                  label={'Max Energy Input from Grid'}
                  placeholder={''}
                  value={formData.max_energy_input_from_grid}
                  onChange={(v) => {
                    handleChange(v, 'max_energy_input_from_grid')
                  }}
                  required={true}
                  error={errors.max_energy_input_from_grid}
                />
              </Grid>
              <Grid item lg={2}>
                <p className={Style.unitSubTextStep1}>MW</p>
              </Grid>
            </Grid>

            {String(formData.scenario_project_type) === '2' && (
              <Grid item lg={12}>
                <Radio
                  items={hybridGenerationType}
                  selectedItem={formData.hybrid_generation_type}
                  onItemSelect={(v) => {
                    handleChange(v, 'hybrid_generation_type')
                  }}
                  label=''
                  horizontal
                />
                {errors.hybrid_generation_type && 'Required field'}
              </Grid>
            )}
            {String(formData.scenario_project_type) === '2' && (
              <Grid item lg={12}>
                <Radio
                  items={coupledType.filter((item) =>
                    formData.hybrid_generation_type === '1' ? item.id !== '2' : item,
                  )}
                  selectedItem={formData.coupled_type}
                  onItemSelect={(v) => {
                    handleChange(v, 'coupled_type')
                  }}
                  label=''
                  horizontal
                />
                {errors.coupled_type && 'Required field'}
              </Grid>
            )}
            {String(formData.scenario_project_type) === '2' &&
              String(formData.hybrid_generation_type) === '2' &&
              String(formData.coupled_type) === '2' && (
                <>
                  <Grid item lg={4}>
                    <FormInput
                      type='text'
                      label={getCapacityLabel(formData.coupled_type, 'Out')}
                      placeholder={''}
                      value={formData.max_bess_inverter_capacity_out}
                      onChange={(v) => {
                        handleChange(v, 'max_bess_inverter_capacity_out')
                      }}
                      required={true}
                      error={errors.max_bess_inverter_capacity_out}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <p className={Style.unitSubTextStep1}>MW</p>
                  </Grid>
                  <Grid item lg={4}>
                    <FormInput
                      type='text'
                      label={getCapacityLabel(formData.coupled_type, 'In')}
                      placeholder={''}
                      value={formData.max_bess_inverter_capacity_in}
                      onChange={(v) => {
                        handleChange(v, 'max_bess_inverter_capacity_in')
                      }}
                      required={true}
                      error={errors.max_bess_inverter_capacity_in}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <p className={Style.unitSubTextStep1}>MW</p>
                  </Grid>
                </>
              )}
            {String(formData.scenario_project_type) === '2' &&
              ['1', '2'].includes(String(formData.hybrid_generation_type)) &&
              String(formData.coupled_type) === '1' && (
                <>
                  <Grid item lg={formData.coupled_type === '1' ? 4 : 6}>
                    <FormInput
                      type='text'
                      label={getCapacityLabel(formData.coupled_type, 'Out')}
                      placeholder={''}
                      value={formData.max_bess_inverter_capacity_out}
                      onChange={(v) => {
                        handleChange(v, 'max_bess_inverter_capacity_out')
                      }}
                      required={true}
                      error={errors.max_bess_inverter_capacity_out}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <p className={Style.unitSubTextStep1}>MW</p>
                  </Grid>
                  <Grid item lg={formData.coupled_type === '1' ? 4 : 6}>
                    <FormInput
                      type='text'
                      label={getCapacityLabel(formData.coupled_type, 'In')}
                      placeholder={''}
                      value={formData.max_bess_inverter_capacity_in}
                      onChange={(v) => {
                        handleChange(v, 'max_bess_inverter_capacity_in')
                      }}
                      required={true}
                      error={errors.max_bess_inverter_capacity_in}
                    />
                  </Grid>
                  <Grid item lg={2}>
                    <p className={Style.unitSubTextStep1}>MW</p>
                  </Grid>
                </>
              )}

            <Grid item lg={12} gap={12}>
              {String(formData.scenario_project_type) === '2' &&
                ['1', '2'].includes(String(formData.hybrid_generation_type)) &&
                String(formData.coupled_type) === '1' && (
                  <>
                    <Grid item lg={4}>
                      <FormInput
                        type='text'
                        label={'Max VRE Inverter Capacity Out'}
                        placeholder={''}
                        value={formData.max_vre_inverter_capacity_out}
                        onChange={(v) => {
                          handleChange(v, 'max_vre_inverter_capacity_out')
                        }}
                        required={true}
                        error={errors.max_vre_inverter_capacity_out}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <p className={Style.unitSubTextStep1}>MW</p>
                    </Grid>
                  </>
                )}
              <Grid item lg={4}>
                <FormInput
                  type='number'
                  label={'Max discharge capacity of the BESS'}
                  placeholder={''}
                  value={formData.rated_discharge_power.toString()}
                  onChange={(v) => handleChange(v, 'rated_discharge_power')}
                  required={false}
                  error={errors.rated_discharge_power}
                />
              </Grid>
              <Grid item lg={2}>
                <p className={Style.unitSubTextStep1}>MW</p>
              </Grid>

              <Grid item lg={4}>
                <FormInput
                  type='number'
                  label={'Max charge capacity of the BESS'}
                  placeholder={''}
                  value={formData.rated_charge_power.toString()}
                  onChange={(v) => handleChange(v, 'rated_charge_power')}
                  required={false}
                  error={errors.rated_charge_power}
                />
              </Grid>
              <Grid item lg={2}>
                <p className={Style.unitSubTextStep1}>MW</p>
              </Grid>
            </Grid>
            <Grid item lg={12} gap={12}>
              <Grid item lg={4}>
                <p className={Style.labelHead}>
                  <b>BOL storage capacity</b>
                </p>
                <FormInput
                  type='number'
                  label={''}
                  placeholder={''}
                  value={formData.bol_storage_capacity.toString()}
                  onChange={(v) => handleChange(v, 'bol_storage_capacity')}
                  required={false}
                />
              </Grid>
              <Grid item lg={2} cssClass={Style.alignContent}>
                <p className={Style.unitSubTextStep1}>MWh</p>
              </Grid>
              <Grid item lg={4}>
                <p className={Style.labelHead}>
                  <b>
                    Inverter capacity <InfoTooltip show='Relevant for inertia service if applicable' />
                  </b>
                </p>
                <FormInput
                  type='number'
                  label={''}
                  placeholder={''}
                  value={formData.inverter_capacity.toString()}
                  onChange={(v) => handleChange(v, 'inverter_capacity')}
                  required={true}
                  error={errors.inverter_capacity}
                />
              </Grid>
              <Grid item lg={2} cssClass={Style.alignContent}>
                <p className={Style.unitSubTextStep1}>MVA</p>
              </Grid>
            </Grid>
          </Grid>
        </AccordionPanel>
        <AccordionPanel
          cssContentClass={Style.accordionpanel}
          panelId='p2'
          label={
            <div className={Style.p1Main}>
              <h3 className={Style.p1Head}>Solve parameters</h3>
            </div>
          }
        >
          <Grid row gap={12}>
            <Grid item lg={4}>
              <p className={Style.labelHead}>
                <b>
                  Solve Interval Hours{' '}
                  <InfoTooltip show='Creates output based on a limited solve interval subset of that data' />
                </b>
              </p>
              <FormInput
                type='text'
                label={''}
                placeholder={''}
                value={formData.solve_interval_hours.toString()}
                onChange={(v) => {
                  handleChange(v, 'solve_interval_hours')
                }}
                required={true}
                error={errors.solve_interval_hours}
              />
            </Grid>
            <Grid item lg={4}>
              <p className={Style.labelHead}>
                <b>
                  Window Hours{' '}
                  <InfoTooltip show='Window of input data to optimise trading behaviour within that window' />
                </b>
              </p>
              <FormInput
                type='text'
                label={''}
                placeholder={''}
                value={formData.window_hours.toString()}
                onChange={(v) => {
                  handleChange(v, 'window_hours')
                }}
                required={true}
                error={errors.window_hours}
              />
            </Grid>
            <Grid item lg={4}>
              <div className={Style.full_width}>
                <p className={Style.labelHead}>
                  <b>
                    Sampling Interval{' '}
                    <InfoTooltip show='Optimizer resamples market data down to a lower time resolution so it can run in a practical time. All market prices will be downsampled to that resolution (and averaged).' />
                  </b>
                </p>
                <Dropdown
                  label=''
                  items={SamplingIntervalHeader}
                  selectedItem={formData.time_res}
                  onSelectItem={(v) => handleChange(v, 'time_res')}
                  required={true}
                  optionsHeight={200}
                />
              </div>
            </Grid>
          </Grid>
        </AccordionPanel>
      </Accordion>
    </div>
  )
}
