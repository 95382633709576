import {
  Button,
  ComboBox,
  Container,
  DateInput,
  Dropdown,
  FormInput,
  Grid,
  IOption,
} from '@aurecon-creative-technologies/styleguide'
import { FC, useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'
import { FORECAST_FILTER_DROPDOWN_ITEMS } from 'src/config/constant'
import { ForecastItem } from 'src/models/api/Forecasting'
import { getAllForecastData } from 'src/stores/AppStore'
import Style from '../styles/TableListFilter.module.sass'
import classNames from 'classnames'

interface FilterProps {
  nameFilter: string[]
  setNameFilter: (value: string[]) => void
  setYearsFilter: (value: string) => void
  periodFilter: string
  setPeriodFilter: (value: string) => void
  createdDateFilter: { startDate: Date | null; endDate: Date | null }
  setCreatedDateFilter: (value: { startDate: Date | null; endDate: Date | null }) => void
  pageName: string
}

const TableListFilter: FC<FilterProps> = ({
  nameFilter,
  setNameFilter,
  setYearsFilter,
  periodFilter,
  setPeriodFilter,
  createdDateFilter,
  setCreatedDateFilter,
  pageName,
}) => {
  const [activeFilter, setActiveFilter] = useState<string | null>(null)
  const [selectedItemFilter, setSelectedItemFilter] = useState<string | number | undefined>()
  const [nameFilterItems, setNameFilterItems] = useState<IOption[]>([])
  const [selectNameFilter, setSelectNameFilter] = useState<string>('')
  const forecastData = useRecoilValue(getAllForecastData)

  useEffect(() => {
    if (Array.isArray(forecastData)) {
      const transformedData = forecastData?.map((item: ForecastItem) => ({
        id: item['id'],
        value: item['file_name'],
      }))
      setNameFilterItems(transformedData)
    }
  }, [forecastData])

  const handleAddFilter = (v?: string | number | undefined) => {
    if (v !== undefined && activeFilter !== String(v)) {
      setActiveFilter(String(v))
    }
    setSelectedItemFilter(undefined)
  }

  const handleClearFilters = () => {
    setSelectedItemFilter(undefined)
    setActiveFilter(null)
    setNameFilter([])
    setYearsFilter('')
    setPeriodFilter('')
    setCreatedDateFilter({ startDate: null, endDate: null })
  }

  const renderOnSelectMultipleItems = (selectedItems: string) => {
    setSelectNameFilter(selectedItems)
    setNameFilter([...nameFilter, selectedItems])
  }

  const renderFilterInput = () => {
    switch (activeFilter) {
      case '0':
        return (
          <ComboBox
            openOnFocus={false}
            showIcon={false}
            options={nameFilterItems.filter((item) => !nameFilter.includes(String(item.id)))}
            cssClass={Style.dropdownPlaceholder}
            placeholder='File Name'
            onSelect={(selectedItems) => {
              renderOnSelectMultipleItems(String(selectedItems?.id))
            }}
            selected={selectNameFilter}
          />
        )
      case '1':
        return pageName !== 'scenarioList' ? (
          <FormInput
            type='number'
            key={'period'}
            value={periodFilter}
            onChange={(value) => setPeriodFilter(value)}
            placeholder='Period'
          />
        ) : null
      case '2':
        return (
          <DateInput
            key={'CreatedDate'}
            dates={createdDateFilter}
            onDateChange={(dates) => setCreatedDateFilter(dates)}
            cssClass={Style.dateInputElement}
            rangeType
          />
        )
      default:
        return null
    }
  }

  return (
    <Container fluid cssClass={(Style.topContainer, Style.paddingX, Style.spaceBetween)}>
      <div className={Style.filterContainer}>
        <Button cssClass={classNames(Style.marginRight, Style.iconBg)} type='custom' size='medium' icon='filter_alt' />

        <Grid row gap={6}>
          {!activeFilter && (
            <Grid item xs={12}>
              <Dropdown
                items={
                  pageName === 'scenarioList'
                    ? FORECAST_FILTER_DROPDOWN_ITEMS.filter((item) => item.label !== 'Period').map((item) =>
                        item.label === 'Forecast File Name' ? { ...item, label: 'Associated Forecast File' } : item,
                      )
                    : FORECAST_FILTER_DROPDOWN_ITEMS
                }
                placeholder='Filter'
                selectedItem={selectedItemFilter}
                onSelectItem={(v) => handleAddFilter(v)}
                optionsHeight={200}
                size='medium'
                cssClass={classNames('is-custom', Style.dropdownPlaceholder)}
              />
            </Grid>
          )}
          {activeFilter && (
            <Grid item xs={12} sm={10} md={10} lg={10}>
              {renderFilterInput()}{' '}
            </Grid>
          )}

          {activeFilter && (
            <Grid cssClass={Style.closeIcon}>
              <Button
                cssClass={Style.mt10}
                type='icon'
                rounded
                size='extra small'
                icon='close'
                onClick={() => setActiveFilter(null)}
              />
            </Grid>
          )}
        </Grid>
      </div>
      <Button
        type='custom'
        cssClass={Style.clearFilterText}
        label='Clear Filters'
        size='medium'
        onClick={handleClearFilters}
      />
    </Container>
  )
}

export default TableListFilter
